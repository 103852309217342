import fileMetadata from '~/scripts/output/assetIcons/fileMetadata.json'

export const shortString = (arg: string, len?: number[]) => {
  return arg.slice(0, len?.[0] ?? 6) + '...' + arg.slice(arg.length - (len?.[1] ?? 4))
}

const fillZeros = (arg: number) => {
  return `00${arg}`.slice(-2)
}
export const secondsToTime = (arg: number, symbol: string = ':') => {
  const minutes = Math.floor(arg / 60)
  const seconds = Math.floor(arg % 60)

  return `${fillZeros(minutes)}${symbol}${fillZeros(seconds)}`
}

export const formatMsToDate = (milliseconds: number | string) => {
  const date = new Date(Number(milliseconds))
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${year}-${month}-${day} ${hours}:${minutes}`
} // Adjust the path as needed

export const getAssetIcon = (assetId: string): string => {
  if (assetId.split('.')[0] === 'SOL') {
    assetId = `SOLANA.${encodeURIComponent(assetId.split('.')[1])}`
  }

  const uriAssetId = encodeURIComponent(assetId.toLowerCase().replace('$', '').replace('%24', ''))
  const metadata: { [K in string]?: string } = fileMetadata
  const extension = metadata[uriAssetId] || 'png'

  return `https://crispy.sfo3.cdn.digitaloceanspaces.com/${uriAssetId}.${extension}`
}

export function sortByBalanceAndName(a: RouteEndPointWithBalance, b: RouteEndPointWithBalance) {
  if (a.balanceUsd > b.balanceUsd) return -1
  if (a.balanceUsd < b.balanceUsd) return 1
  if (a.balanceUsd === 0 && b.balanceUsd === 0) {
    if (a.assetName < b.assetName) return -1
    if (a.assetName > b.assetName) return 1
    return 0
  }
  return 0
}
