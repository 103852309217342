import { toHexString } from '@swapkit/toolbox-evm/src/helpers'
import { useRoute, useRouter, useRuntimeConfig } from 'nuxt/app'
import { Chain, ChainId, ChainToChainId, WalletOption } from '@swapkit/helpers'
import {
  type QuoteRequest,
  type QuoteResponse,
  type QuoteResponseRoute,
  type Token,
} from '@swapkit/api'
import { SwapKitApi } from '@swapkit/api'
import { type TokenListProvidersResponse } from '@swapkit/api/src/thorswapApiV2/types'
import axios from 'axios'
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import * as tokenPackages from '@swapkit/tokens'
import type { ProviderName, TokensResponseV2 } from '@swapkit/sdk'
import { useSwapkitWalletStore } from './swapkitWalletStore'
import { swap } from '~/clients/swapkit'
import type { RouteEndPoint } from '~/types'
import {
  allRoutesSwapkit,
  getNormalizedProviderName,
  getTokenTradingPairs,
  providerTokenlistMap,
} from '~/utils/swapkit'
import type { StatusFees, SwapkitStatusParams } from '~/utils/status'
import type { WalletChain } from '~/wallets/swapkit'

export type SwapParams = {
  expectedOutputUsd: number
  fees: StatusFees
  fromChain: WalletChain
  route: QuoteResponseRoute
  toChain: WalletChain
}

export type TransactionSchema = {
  status: 'running' | 'success' | 'failed'
  toAmount: string
}

// type SwapkitProvider = {
//   name: string
//   provider: string
//   keywords: string[]
//   count: number
//   logoURI: string
//   url: string
//   supportedActions: string[]
//   supportedChainIds: string[]
// }

type SwapkitRouteState = {
  abortController: AbortController | null
  routes: RouteEndPoint[]
  connectedPairs: Map<
    string,
    {
      tokens: TokensResponseV2['tokens']
      providers: ProviderName[]
    }
  >
  chainMap: { [key: string]: string }
  assetMap: { [key: string]: string }
  providers: TokenListProvidersResponse
  affiliateBps: number
}

const initialState: SwapkitRouteState = {
  abortController: null,
  routes: [],
  connectedPairs: new Map<
    string,
    {
      tokens: TokensResponseV2['tokens']
      providers: ProviderName[]
    }
  >(),
  chainMap: {},
  assetMap: {},
  providers: [],
  affiliateBps: 45,
}
export const useSwapkitRouteStore = defineStore('SwapkitRoute', {
  state: () => initialState,
  getters: {
    referrerFeeRatio: (state) => {
      const { isCCMember } = storeToRefs(useSwapkitWalletStore())
      return isCCMember.value ? 0 : state.affiliateBps / 10000
    },
    referrerFeePercentage: (state): number => {
      const { isCCMember } = storeToRefs(useSwapkitWalletStore())
      return isCCMember.value ? 0 : state.affiliateBps / 100
    },
    getProviderLogo: (state) => {
      return (provider: string): string | undefined => {
        if (!state.providers || !Array.isArray(state.providers)) return undefined
        const p = state.providers.find((p) => p.provider === provider)
        return (p && 'logoURI' in p && typeof p.logoURI === 'string' && p.logoURI) || undefined
      }
    },
    getProvider: (state) => {
      return (provider: string) => state.providers.find((p) => p.provider === provider)
    },
    getOutputRoutes: () => {
      const swapkitProvidersArray = useRuntimeConfig().public.SWAPKIT_PROVIDERS?.split(',') ?? []

      const uniqueSwapkitProviders = Array.from(new Set(swapkitProvidersArray))

      const validTokenLists = Array.from(
        new Set(
          uniqueSwapkitProviders.map((provider) => {
            const tokenList = providerTokenlistMap[provider]
            if (!tokenList) {
              const error = `Invalid provider: ${provider}.`
              window.newrelic?.noticeError(error)
              console.error(error)
            }
            return tokenList
          }),
        ),
      )

      type TokenPackagesType = typeof tokenPackages

      const providersData: TokenPackagesType[keyof TokenPackagesType][] = Object.entries(
        tokenPackages,
      ).reduce(
        (acc, [key, entry]) => {
          if (validTokenLists.includes(key)) {
            acc.push(entry)
          }
          return acc
        },
        [] as TokenPackagesType[keyof TokenPackagesType][],
      )

      const tokensByProvider = new Map<
        string,
        {
          tokens: Token[]
          providers: string[]
        }
      >()

      // Example code from swapkit once we have dex aggregation
      //   if (withTradingPairs) {
      // const chainableTokens = providersData
      // .filter(({ data }) => {
      //   return IS_DEV_API || !UNCHAINABLE_PROVIDERS.includes(data?.provider || '')
      // })
      // .reduce((acc, { tokens }) => acc.concat(tokens) as Token[], [] as Token[])

      for (const { tokens, provider } of providersData) {
        if (!tokens) return
        // const isProviderChainable = !(UNCHAINABLE_PROVIDERS.includes(data.provider) || IS_DEV_API)
        const isProviderChainable = true

        for (const token of tokens) {
          const existingTradingPairs = tokensByProvider.get(token.identifier.toUpperCase()) || {
            tokens: [],
            providers: [],
          }
          const tradingPairs = isProviderChainable
            ? { tokens, providers: [provider] } // { tokens: chainableTokens, providers: [Provider.V1_PROVIDERS] }
            : { tokens, providers: [provider] }

          tokensByProvider.set(token.identifier.toUpperCase(), {
            // @ts-expect-error
            tokens: existingTradingPairs.tokens.concat(tradingPairs.tokens),
            providers: existingTradingPairs.providers.concat(tradingPairs.providers),
          })
        }
      }
      return tokensByProvider

      //   }
    },
  },
  actions: {
    initRoutes() {
      this.routes = allRoutesSwapkit(useRuntimeConfig().public.SWAPKIT_PROVIDERS)
    },
    async initConnectedPairs() {
      const pairs = await getTokenTradingPairs(
        (useRuntimeConfig().public.SWAPKIT_PROVIDERS.split(',') as ProviderName[]) ?? [],
      )
      if (pairs) {
        this.connectedPairs = pairs
      }
    },
    async initSwapkitProviders() {
      /**
       * currently the api doesn't check response.ok
       * so the value might be an error object
       */
      const providers = await SwapKitApi.getTokenListProvidersV2()
      // if (!tokenListProvidersSchema.safeParse(providers).success) {
      //   window.newrelic?.noticeError('Invalid token list providers response')
      //   return
      // }
      this.providers = providers
    },
    async fetchQuote(
      nonce: number,
      quoteRequest: QuoteRequest,
    ): Promise<{
      nonce: number
      quoteStreaming: {
        integration: 'swapkit'
        quote: QuoteResponseRoute
      } | null
      quoteSwap: {
        integration: 'swapkit'
        quote: QuoteResponseRoute
      } | null
    }> {
      this.abortController?.abort('canceled')
      const abortController = new AbortController()
      this.abortController = abortController

      const route = useRoute()
      const runtimeConfig = useRuntimeConfig()
      const { isCCMember } = storeToRefs(useSwapkitWalletStore())
      const swapkitProviders = route.query.providers
      const providers: string[] | undefined = Array.isArray(swapkitProviders)
        ? swapkitProviders.filter((provider) => typeof provider === 'string')
        : (swapkitProviders ?? runtimeConfig.public.SWAPKIT_PROVIDERS)?.split(',')

      const quoteRequestParams: QuoteRequest = {
        ...quoteRequest,
        affiliate: 'ELD',
        affiliateFee: isCCMember.value ? 0 : 45,
        includeTx: true,
        ...(providers ? { providers } : null),
      }

      try {
        const quoteResponse: QuoteResponse = await (
          await fetch(runtimeConfig.public.SWAPKIT_API + '/quote', {
            body: JSON.stringify(quoteRequestParams),
            headers: { 'content-type': 'application/json' },
            method: 'POST',
            signal: abortController.signal,
          })
        ).json()
        // const quoteResponse = await SwapKitApi.getSwapQuoteV2(quoteRequestParams) // product api endpoint
        if (quoteResponse.routes.length <= 0) {
          throw new Error('No route is available at the moment, please try again later.')
        }
        if ('error' in quoteResponse) {
          throw quoteResponse
        }

        const quoteStreaming: QuoteResponseRoute | null =
          quoteResponse.routes
            .filter((route) => route.providers[0].includes('STREAMING'))
            .sort((a, b) => parseFloat(b.expectedBuyAmount) - parseFloat(a.expectedBuyAmount))
            .at(0) ?? null

        const quoteSwap: QuoteResponseRoute | null =
          quoteResponse.routes
            .filter((route) => !route.providers[0].includes('STREAMING'))
            .sort((a, b) => parseFloat(b.expectedBuyAmount) - parseFloat(a.expectedBuyAmount))
            .at(0) ?? null

        // SWapkit SDK replaces the original router contracts with wrapper contracts

        // no mayachain wrapper:  0x700E97ef07219440487840Dc472E7120A7FF11F4
        // nothing to replace
        // mayachain:             0x700E97ef07219440487840Dc472E7120A7FF11F4
        // mayachain streaming    0x700E97ef07219440487840Dc472E7120A7FF11F4

        // thorchain wrapper:     0xDd40F17b848cE3a30b4F71652535E744F45fA5A3
        // replace with:
        // thorchain:             0xD37BbE5744D730a1d98d8DC97c42F0Ca46aD7146

        return {
          nonce,
          quoteStreaming: quoteStreaming && { integration: 'swapkit', quote: quoteStreaming },
          quoteSwap: quoteSwap && { integration: 'swapkit', quote: quoteSwap },
        }
      } catch (error) {
        if (
          typeof error === 'object' &&
          error &&
          'response' in error &&
          error.response instanceof Response
        ) {
          throw await error.response.json()
        }
        throw error
      }
    },

    navigateToStatus({
      expectedOutputUsd,
      fees,
      fromChain,
      route,
      toChain,
      txHash,
    }: SwapParams & { txHash: string }) {
      const router = useRouter()

      const chainToChainId: { [K in string]?: ChainId } = ChainToChainId
      const query: SwapkitStatusParams = {
        chainId: chainToChainId[fromChain] ?? null,
        estimatedTimeS: 5 * 60, // TODO once estimation is correct, use: (route.estimatedTime?.total || 0).toString(),
        feesAffiliateUsd: fees.affiliateUsd,
        feesProtocolUsd: fees.protocolUsd,
        feesTotalUsd: fees.totalUsd,
        fromAddress: route.sourceAddress,
        fromAsset: route.sellAsset,
        fromChain,
        hash: txHash,
        inAmount: route.sellAmount,
        openTxTrackerModal: 'true',
        outAmount: route.expectedBuyAmount,
        outputAmountMin: route.expectedBuyAmountMaxSlippage,
        outputAmountUsd: expectedOutputUsd,
        startMs: Date.now(),
        swapper: getNormalizedProviderName(route.providers[0] ?? ''),
        swapperIcon: route.providers[0] ? (this.getProviderLogo(route.providers[0]) ?? '') : '',
        toAddress: route.destinationAddress,
        toAsset: route.buyAsset,
        toChain,
      }

      router.push({ query })
    },
    async submitSwap(params: SwapParams): Promise<string> {
      const { route } = params
      const inChain = route.sellAsset.split('.').at(0)
      const { wallets } = useSwapkitWalletStore()
      if (
        route.tx &&
        inChain === Chain.Arbitrum &&
        wallets[inChain]?.walletType === WalletOption.KEYSTORE &&
        'provider' in wallets[inChain]
      ) {
        const { hash } = await wallets[inChain].provider.signAndSendTransaction({
          ...route.tx,
          value: toHexString(BigInt(route.tx.value)),
        })
        this.navigateToStatus({ ...params, txHash: hash })
        return hash
      }
      if (
        route.tx &&
        inChain === Chain.Ethereum &&
        wallets[inChain]?.walletType === WalletOption.KEYSTORE &&
        'provider' in wallets[inChain]
      ) {
        const { hash } = await wallets[inChain].provider.signAndSendTransaction({
          ...route.tx,
          value: toHexString(BigInt(route.tx.value)),
        })
        this.navigateToStatus({ ...params, txHash: hash })
        return hash
      }
      const txHash = await swap({ route })
      this.navigateToStatus({ ...params, txHash })
      return txHash
    },
    fetchStatus({ hash, chainId }: { hash: string; chainId: string }) {
      const runtimeConfig = useRuntimeConfig()
      return axios.post<TransactionSchema>(`${runtimeConfig.public.SWAPKIT_API}/track`, {
        hash,
        chainId,
      })
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSwapkitRouteStore, import.meta.hot))
}
