import { BigNumber } from 'bignumber.js'
import type { FiatPrice } from '~/types/fiatPrice'
import type { State } from '~/types/state'

const DEFAULT_DISPLAY_DECIMALS = 4

const DISPLAY_DECIMALS: { [key: string]: number } = {
  USD: 2,
  'ETH.VXDEFI': 6,
  'BTC.BTC': 8,
  'BTC/BTC': 5,
  'ETH.ETH': 4,
  'ARB.ETH': 4,
  'ETH.USDC': 2,
  'MAYA.CACAO': 3,
  'THOR.RUNE': 3,
  'ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48': 2,
  'ETH.FLIP-0X826180541412D574CF1336D22C0C0A287822678A': 4,
  'ETH.WSTETH-0X7F39C581F595B53C5CB19BD0B3F8DA6C935E2CA0': 4,
  'ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7': 4,
  'DASH.DASH': 4,
  'ETH/ETH': 4,
  'SOLANA.USDC--EPJFWDD5AUFQSSQEM2QN1XZYBAPC8G4WEGGKZWYTDT1V': 4,
  // cspell:disable-next-line
  'SOLANA.ZYN--PZUAVAUH2TFXGZCBBR6KMXEJSBNGNSPLFOTGJNCTCSD': 4,
  'ETH.TRUMP--0X576E2BED8F7B46D34016198911CDF9886F78BEA7': 4,
  'ETH.ZYN--0X58CB30368CEB2D194740B144EAB4C2DA8A917DCB': 4,
  'SOLANA.BODEN--3PSH1MJ1F7YUFAD5GH6ZJ7EPE8HHRMKMETGV5TSHQA4O': 4,
  'SOLANA.KENIDY--BG9CZR1CMVOCN2UNWWJ9F5RLBMFYRYVCVIKCRCWAWUWR': 4,
  'SOLANA.TREN--HLNTNCG5RD7JYVDUFC1PMCHIUAPOWGN9LVEQEFANQFZB': 4,
  'ETH.APU--0X594DAAD7D77592A2B97B725A7AD59D7E188B5BFA': 4,
  'ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933': 4,
  'ARB.ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': 4,
  'ARB.DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 4,
  'ARB.GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': 4,
  'ARB.GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': 4,
  'ARB.GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': 4,
  'ARB.LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': 4,
  'ARB.LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': 4,
  'ARB.PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': 4,
  'ARB.SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': 4,
  'ARB.TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': 4,
  'ARB.UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': 4,
  'ARB.USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 2,
  'ARB.USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 2,
  'ARB.WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 8,
  'ARB.WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': 4,
  'ARB/ETH': 4,
  'ARB/ARB-0X912CE59144191C1204E64559FE8253A0E49E6548': 4,
  'ARB/DAI-0XDA10009CBD5D07DD0CECC66161FC93D7C9000DA1': 4,
  'ARB/GLD-0XAFD091F140C21770F4E5D53D26B2859AE97555AA': 4,
  'ARB/GMX-0XFC5A1A6EB076A2C7AD06ED22C90D7E710E35AD0A': 4,
  'ARB/GNS-0X18C11FD286C5EC11C3B683CAA813B77F5163A122': 4,
  'ARB/LEO-0X93864D81175095DD93360FFA2A529B8642F76A6E': 4,
  'ARB/LINK-0XF97F4DF75117A78C1A5A0DBB814AF92458539FB4': 4,
  'ARB/PEPE-0X25D887CE7A35172C62FEBFD67A1856F20FAEBB00': 4,
  'ARB/SUSHI-0XD4D42F0B6DEF4CE0383636770EF773390D85C61A': 4,
  'ARB/TGT-0X429FED88F10285E61B12BDF00848315FBDFCC341': 4,
  'ARB/UNI-0XFA7F8980B0F1E64A2062791CC3B0871572F1F7F0': 4,
  'ARB/USDC-0XAF88D065E77C8CC2239327C5EDB3A432268E5831': 4,
  'ARB/USDT-0XFD086BC7CD5C481DCC9C85EBE478A1C0B69FCBB9': 4,
  'ARB/WBTC-0X2F2A2543B76A4166549F7AAB2E75BEF0AEFC5B0F': 4,
  'ARB/WSTETH-0X5979D7B546E38E414F7E9822514BE443A4800529': 4,
}

export function intToBigNumber(int: BigNumber.Value | bigint, decimals: number): BigNumber {
  BigNumber.config({ EXPONENTIAL_AT: 1e9 })

  if (typeof int === 'bigint') {
    int = int.toString()
  }

  const bigDecimals = new BigNumber(10).pow(decimals)
  const float = (int instanceof BigNumber ? int : new BigNumber(int)).div(bigDecimals)

  return float
}
export function intToFloat(...params: Parameters<typeof intToBigNumber>): string {
  return intToBigNumber(...params).toString()
}

export function floatToInt(float: string | number, decimals: number): string {
  BigNumber.config({ EXPONENTIAL_AT: 1e9 })

  const bigDecimals = new BigNumber(10).pow(decimals)
  const int = new BigNumber(float).times(bigDecimals)

  return int.toFixed(0)
}

export function toAmountUsd(
  bigIntValue: bigint,
  decimal: number,
  fiatPrice: State<FiatPrice>,
): State<number> {
  BigNumber.config({ EXPONENTIAL_AT: 1e9 })
  const amount = new BigNumber(intToFloat(bigIntValue, decimal))
  if (fiatPrice.state === 'loaded') {
    return { state: 'loaded', data: amount.multipliedBy(fiatPrice.data.usd).toNumber() }
  } else {
    return fiatPrice
  }
}

export function toAmountUsdSwapkit(float: string, fiatPrice: State<FiatPrice>): State<number> {
  BigNumber.config({ EXPONENTIAL_AT: 1e9 })
  const amount = new BigNumber(float)
  if (fiatPrice.state === 'loaded') {
    return { state: 'loaded', data: amount.multipliedBy(fiatPrice.data.usd).toNumber() }
  } else {
    return fiatPrice
  }
}

export function toPrice(number: string | number | bigint, assetId: string | 'USD'): string {
  const decimals = DISPLAY_DECIMALS[assetId.toUpperCase()] ?? DEFAULT_DISPLAY_DECIMALS

  if (typeof number === 'bigint') {
    number = number.toString()
  }

  if (typeof number === 'string') {
    number = parseFloat(number)
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })

  // Define thresholds for formatting with suffixes
  const thousand = 1000
  const million = 1000000
  const billion = 1000000000

  // Format number with the appropriate suffix
  if (number >= billion) {
    return formatter.format(number / billion) + 'B'
  } else if (number >= million) {
    return formatter.format(number / million) + 'M'
  } else if (number >= 100 * thousand) {
    return formatter.format(number / thousand) + 'K'
  } else {
    return formatter.format(number) // Format normally if less than 100.000
  }
}
export const toPriceUsd = (number: number) => {
  if (number < 0.01) {
    return '< $0.01'
  }
  return '$' + toPrice(number, 'USD')
}

export function toDisplayAmountUsd(amountUsd: State<number>): State<string> {
  BigNumber.config({ EXPONENTIAL_AT: 1e9 })
  if (amountUsd.state === 'loaded') {
    return { state: 'loaded', data: toPrice(amountUsd.data, 'USD') }
  } else {
    return amountUsd
  }
}
