export default {
  "comp-competition-header": () => import("/vercel/path0/layouts/Comp/CompetitionHeader.vue").then(m => m.default || m),
  "comp-main-menu-header-social-link": () => import("/vercel/path0/layouts/Comp/MainMenu/HeaderSocialLink.vue").then(m => m.default || m),
  "comp-main-menu": () => import("/vercel/path0/layouts/Comp/MainMenu/MainMenu.vue").then(m => m.default || m),
  "comp-main-menu-member-btn": () => import("/vercel/path0/layouts/Comp/MainMenu/MemberBtn.vue").then(m => m.default || m),
  "comp-main-menu-mobile-menu-btn": () => import("/vercel/path0/layouts/Comp/MainMenu/MobileMenuBtn.vue").then(m => m.default || m),
  "comp-member-header": () => import("/vercel/path0/layouts/Comp/MemberHeader.vue").then(m => m.default || m),
  "comp-menu-link": () => import("/vercel/path0/layouts/Comp/MenuLink.vue").then(m => m.default || m),
  "comp-tutorial-footer": () => import("/vercel/path0/layouts/Comp/TutorialFooter.vue").then(m => m.default || m),
  "comp-tutorial-header": () => import("/vercel/path0/layouts/Comp/TutorialHeader.vue").then(m => m.default || m),
  "comp-wallet-menu-connected-chain-item": () => import("/vercel/path0/layouts/Comp/WalletMenu/ConnectedChainItem.vue").then(m => m.default || m),
  "comp-wallet-menu-connected-wallet": () => import("/vercel/path0/layouts/Comp/WalletMenu/ConnectedWallet.vue").then(m => m.default || m),
  "comp-wallet-menu-wallet-handler": () => import("/vercel/path0/layouts/Comp/WalletMenu/WalletHandler.vue").then(m => m.default || m),
  "comp-wallet-menu": () => import("/vercel/path0/layouts/Comp/WalletMenu/WalletMenu.vue").then(m => m.default || m),
  "comp-wallet-menu-body": () => import("/vercel/path0/layouts/Comp/WalletMenu/WalletMenuBody.vue").then(m => m.default || m),
  "comp-welcome-dorito": () => import("/vercel/path0/layouts/Comp/WelcomeDorito.vue").then(m => m.default || m),
  "comp-welcome-dorito-bg": () => import("/vercel/path0/layouts/Comp/WelcomeDoritoBg.vue").then(m => m.default || m),
  "layout-header": () => import("/vercel/path0/layouts/LayoutHeader.vue").then(m => m.default || m),
  default: () => import("/vercel/path0/layouts/default.vue").then(m => m.default || m)
}