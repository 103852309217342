<template>
  <NuxtLayout>
    <v-app class="h-full w-full [&>div]:h-full [&>div]:!w-full [&>div]:[all:unset]">
      <NuxtPage />
    </v-app>
  </NuxtLayout>
</template>
<script setup lang="ts">
import { onErrorCaptured, onMounted } from 'vue'
import { useRuntimeConfig } from 'nuxt/app'
import { useSnackbarMessage } from './store/snackbar'
import { getHumanError } from './utils/humanErrors'
import { useMarketStore } from './store/marketStore'
import { useSwapkitRouteStore } from './store/swapkitRouteStore'
import { useRouteStore } from '@/store/routeStore'

const { fetchMarkets } = useMarketStore()
const routeStore = useRouteStore()
const swapkitRouteStore = useSwapkitRouteStore()
const runtimeConfig = useRuntimeConfig()
const snackbar = useSnackbarMessage()

onMounted(() => {
  routeStore.initRoutes()
  swapkitRouteStore.initConnectedPairs()
  fetchMarkets({ pageSize: 50 })
  // Mava Widget is only visible with the docked devtools closed
  const mava = document.createElement('script')
  mava.src = 'https://widget.mava.app'
  mava.defer = true
  mava.id = 'MavaWebChat'
  mava.setAttribute('widget-version', 'v2')
  mava.setAttribute('enable-sdk', 'false')
  mava.setAttribute(
    'data-token',
    '46e424a016c1f3b4c812017fa3e3eed1b884da922dc60091191f48f559e43e2d',
  )
  mava.async = true
  document.body.appendChild(mava)

  if (runtimeConfig.public.NODE_ENV !== 'production') {
    return
  }
  /**
   * App visit
   * @reference https://v2.growthchannel.io/activate/pixels
   */
  const appVisitPixel = document.createElement('script')
  appVisitPixel.async = true
  appVisitPixel.defer = true
  appVisitPixel.src = 'https://pxl.growth-channel.net/s/5f1331d7-7f35-4093-92b3-a5d498e401eb'
  document.body.appendChild(appVisitPixel)
})

// (err, instance, info)
onErrorCaptured((err) => {
  snackbar.addError({
    text: getHumanError(err.toString()),
    duration: 10000,
  })
  console.error(err)
  window.newrelic?.noticeError(err)
  return false
})
</script>
