import { useRuntimeConfig } from 'nuxt/app'
import { RangoClient, type SwapFee } from 'rango-sdk-basic'
import { useFiatPriceStore } from '~/store/fiatPriceStore'
import { getAssetId } from '~/utils/main'
import { intToBigNumber } from '~/utils/numbers'
import type { Fees, FeesDisplay } from '~/utils/swap'

export const useRangoClient = () => {
  const runtimeConfig = useRuntimeConfig()
  return new RangoClient('', false, runtimeConfig.public.RANGO_API_URL)
}

export const useRangoReferrers = () => {
  const runtimeConfig = useRuntimeConfig()
  return {
    ETH: runtimeConfig.public.RANGO_REFERRER_ETH,
    KUJIRA: runtimeConfig.public.RANGO_REFERRER_KUJIRA,
    MAYA: runtimeConfig.public.RANGO_REFERRER_MAYA,
    THOR: runtimeConfig.public.RANGO_REFERRER_THOR,
  }
}

export const useRango = () => {
  const fiatPriceStore = useFiatPriceStore()
  const feeToFeeUsd = (fee: SwapFee): Fees[keyof Fees] => {
    const usdValue =
      fee.token.usdPrice ??
      fiatPriceStore.getFiatPriceAmount(
        getAssetId(fee.token.blockchain, fee.token.symbol, fee.token.address),
      )
    return intToBigNumber(fee.amount, fee.token.decimals).multipliedBy(usdValue).toNumber()
  }
  const swapFeesToFeesDisplay = ({
    affiliateFeeUsd,
    expectedOutputUsd,
    swapFees,
    valueIn,
  }: {
    affiliateFeeUsd: number
    expectedOutputUsd: number
    swapFees: SwapFee[]
    valueIn: number
  }): FeesDisplay => {
    const protocolFeeUsd = valueIn - affiliateFeeUsd - expectedOutputUsd
    const initialFees: Fees = {
      affiliateUsd: affiliateFeeUsd,
      protocolUsd: Math.max(protocolFeeUsd, 0),
      networkUsd: 0,
    }
    const fees = swapFees.reduce<Fees>((currentFees, fee) => {
      switch (fee.name) {
        case 'Affiliate Fee': {
          return {
            ...currentFees,
            affiliateUsd: currentFees.affiliateUsd + feeToFeeUsd(fee),
          }
        }
        case 'Swapper Fee': {
          return {
            ...currentFees,
            protocolUsd: currentFees.protocolUsd + feeToFeeUsd(fee),
          }
        }
        case 'Network Fee': {
          return {
            ...currentFees,
            networkUsd: currentFees.networkUsd + feeToFeeUsd(fee),
          }
        }
        default:
          return currentFees
      }
    }, initialFees)
    const totalUsd = fees.affiliateUsd + fees.protocolUsd
    return {
      affiliateUsd: fees.affiliateUsd,
      protocolUsd: fees.protocolUsd,
      networkUsd: fees.networkUsd,
      totalUsd,
    }
  }
  return {
    swapFeesToFeesDisplay,
  }
}
