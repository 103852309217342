import {
  AssetValue,
  BaseDecimal,
  Chain,
  type CosmosChain,
  CosmosChains,
  type EVMChain,
  EVMChains,
  RequestClient,
  type WalletChain as SwapkitWalletChain,
  type UTXOChain,
  UTXOChains,
  getChainIdentifier,
} from '@swapkit/helpers'
import { type PoolDetail, type PoolPeriod, QuoteResponseSchema, SwapKitApi } from '@swapkit/api'
import type { SaverDetails } from '@swapkit/api/src/midgard/types.ts'

import * as tokenPackages from '@swapkit/tokens'
import { z } from 'zod'
import { ChainId, ProviderName } from '@swapkit/helpers'
import type BigNumber from 'bignumber.js'
import type { TokensResponseV2 } from '@swapkit/sdk'
import { getAssetIcon } from './helpers'
import { toPrice } from './numbers'
import {
  type KeplrChain,
  type WalletChain,
  type XdefiChain,
  keplrChains,
  xdefiChains,
} from '~/wallets/swapkit'
import manualSwapkitAssets from '~/config/manualSwapkitAssets.json'
import type {
  LiquidityPosition,
  LiquidityPositionDetail,
  LiquidityPositionDetailMAYARaw,
  LiquidityPositionDetailTHORRaw,
  LiquidityPositionMAYARaw,
  LiquidityPositionTHORRaw,
  SaverDetail,
  SaverQuoteDepositParamsRaw,
  SaverQuoteParams,
  SaverQuoteWithdrawParamsRaw,
  SaversQuote,
  SaversQuoteRaw,
} from '~/types/earn'
import { RangoChain } from '~/types/rango'

export const swapkitChains = Object.values(Chain).filter<SwapkitWalletChain>(
  (chain) => chain !== Chain.Chainflip && chain !== Chain.Radix,
)

export const isEldChain = (chain: string): chain is WalletChain => {
  return chain === RangoChain.Solana || isSwapkitChain(chain)
}
export const isSwapkitChain = (chain: string): chain is Chain => {
  const chains: string[] = swapkitChains
  return chains.includes(chain)
}
export const isXdefiChain = (chain: string): chain is XdefiChain => {
  const chains: string[] = Object.values(xdefiChains)
  return chains.includes(chain)
}
export const isEvmChain = (chain: string): chain is EVMChain => {
  const chains: ReadonlyArray<string> = EVMChains
  return chains.includes(chain)
}
export const isUtxoChain = (chain: string): chain is UTXOChain => {
  const chains: ReadonlyArray<string> = UTXOChains
  return chains.includes(chain)
}
export const isCosmosChain = (chain: string): chain is CosmosChain => {
  const chains: ReadonlyArray<string> = CosmosChains
  return chains.includes(chain)
}
export const isKeplrChain = (chain: string): chain is KeplrChain => {
  const chains: ReadonlyArray<string> = keplrChains
  return chains.includes(chain)
}
export const providerTokenlistMap: { [key: string]: string } = {
  CHAINFLIP: 'ChainflipList',
  MAYACHAIN: 'MayaList',
  MAYACHAIN_STREAMING: 'MayaList',
  ONEINCH: 'OneInchList',
  PANCAKESWAP: 'PancakeswapList',
  PANGOLIN_V1: 'PangolinList',
  SUSHISWAP_V2: 'SushiswapList',
  THORCHAIN: 'ThorchainList',
  THORCHAIN_STREAMING: 'ThorchainList',
  TRADERJOE_V1: 'TraderjoeV1List',
  TRADERJOE_V2: 'TraderjoeV2List',
  UNISWAP_V2: 'UniswapV2List',
  UNISWAP_V3: 'UniswapV3List',
}
type SwapkitChain = typeof Chain
type ChainName = {
  [K in keyof SwapkitChain]: SwapkitChain[K] extends WalletChain ? K : never
}[keyof SwapkitChain]
export const ChainToChainName = Object.entries(Chain).reduce(
  (acc, [chainName, chain]) => {
    return { ...acc, [chain]: chainName === 'Maya' ? 'MAYAChain' : chainName }
  },
  {} as {
    [key in WalletChain]: ChainName
  },
)
export const allRoutesSwapkit = (swapkitProviders: string | undefined = undefined) => {
  const tokenMap = new Map<
    string,
    {
      asset: string
      assetId: string
      assetName: string
      ticker: string
      assetAddress: string | null
      decimals: number
      chain: SwapkitWalletChain
      chainName: ChainName | 'Radix'
      logoURI: string
      integrations: Integration[]
    }
  >()

  type TokenPackagesType = typeof tokenPackages

  const swapkitProvidersArray = swapkitProviders?.split(',') ?? Object.keys(providerTokenlistMap)
  const uniqueSwapkitProviders = Array.from(new Set(swapkitProvidersArray))

  const validTokenLists = Array.from(
    new Set(
      uniqueSwapkitProviders.map((provider) => {
        const tokenList = providerTokenlistMap[provider]
        if (!tokenList) {
          const error = `Invalid provider: ${provider}.`
          window.newrelic?.noticeError(error)
          console.error(error)
        }
        return tokenList
      }),
    ),
  )

  type TokenPackages = {
    [K in keyof TokenPackagesType]?: TokenPackagesType[keyof TokenPackagesType]
  }
  const filteredTokenPackages: TokenPackages = (
    Object.keys(tokenPackages) as Array<keyof TokenPackagesType>
  ).reduce<TokenPackages>((obj, key) => {
    if (!validTokenLists.includes(key)) {
      return obj
    }
    obj[key] = tokenPackages[key]
    return obj
  }, {})

  for (const tokenList of Object.values(filteredTokenPackages)) {
    for (const { identifier, chain, ticker, ...rest } of tokenList.tokens) {
      /**
       * @example BaseDecimal['ARB'] // returns 18
       */
      const baseDecimal = BaseDecimal[chain as keyof typeof BaseDecimal]
      const assetIconId = identifier.includes('/')
        ? identifier.split('.')[1].replace('/', '.')
        : identifier
      tokenMap.set(identifier.toUpperCase(), {
        asset: identifier.toUpperCase(),
        assetId: identifier.toUpperCase(),
        assetName: ticker,
        ticker,
        assetAddress: 'address' in rest ? rest.address : null,
        decimals: 'decimals' in rest ? rest.decimals : baseDecimal,
        chain: chain as SwapkitWalletChain,
        chainName: chain === 'XRD' ? 'Radix' : ChainToChainName[chain],
        logoURI: getAssetIcon(assetIconId),
        integrations: ['swapkit'],
      })
    }
  }
  return Array.from(tokenMap.values()).filter((token) => token.assetId !== 'BNB.BNB')
}

export const swapkitQuoteSchema = QuoteResponseSchema.shape.routes.element

export const curatedRoutesSwapkit = () => {
  const manualSwapkitAssetsCaps = manualSwapkitAssets.map((assetId) => assetId.toUpperCase())
  const curated = allRoutesSwapkit().filter((token) =>
    manualSwapkitAssetsCaps.includes(token.assetId.toUpperCase()),
  )
  return curated
}

export function getMAYAChainPools(period: PoolPeriod) {
  return RequestClient.get<PoolDetail[]>('https://node.eldorado.market/midgard/v2/pools', {
    searchParams: { period },
  })
}

export function formatDateFromUnixTimestamp(unixTimestamp: string): string {
  const date = new Date(parseInt(unixTimestamp) * 1000) // Convert Unix timestamp to milliseconds
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' }
  return date.toLocaleDateString('en-GB', options)
}

export async function getMAYALiquidityPositionsRaw(addresses: string[]) {
  const promises = addresses.map((address) =>
    RequestClient.get<{ pools: LiquidityPositionMAYARaw[] }>(
      `https://node.eldorado.market/midgard/v2/member/${address}`,
    ),
  )
  const res = await Promise.allSettled(promises)

  const successfulResults = res.flatMap(
    (result) => (result.status === 'fulfilled' && result.value?.pools) || [],
  )
  const seen = new Set<string>()

  const uniqueResults = successfulResults.filter((lp) => {
    const key = `${lp.assetAddress}-${lp.runeAddress}-${lp.pool}`
    if (seen.has(key)) {
      return false
    } else {
      seen.add(key)
      return true
    }
  })

  return uniqueResults
}

export async function getMAYALiquidityPositions(addresses: string[]): Promise<LiquidityPosition[]> {
  const rawLiquidityPositions = await getMAYALiquidityPositionsRaw(addresses)

  return rawLiquidityPositions.map((rawPosition): LiquidityPosition => {
    const asset = AssetValue.from({
      asset: rawPosition.pool,
      value: rawPosition.assetAdded,
      fromBaseDecimal: BaseDecimal.THOR,
    })
    const assetIcon = getAssetIcon(asset.toString())

    const native = AssetValue.from({
      asset: 'MAYA.CACAO',
      value: rawPosition.runeAdded,
      fromBaseDecimal: 10,
    })
    const nativeIcon = getAssetIcon(native.toString())
    return {
      ...rawPosition,
      provider: 'mayachain',
      assetRegisteredAddress: rawPosition.assetAddress,
      asset,
      assetAmountDisplay: toPrice(asset.getValue('string'), asset.ticker),
      assetIcon,
      assetPending: AssetValue.from({
        asset: rawPosition.pool,
        value: rawPosition.assetPending,
        fromBaseDecimal: BaseDecimal.THOR,
      }),
      assetWithdrawn: AssetValue.from({
        asset: rawPosition.pool,
        value: rawPosition.assetWithdrawn,
        fromBaseDecimal: BaseDecimal.THOR,
      }),
      native,
      nativeAmountDisplay: toPrice(native.getValue('string'), native.ticker),
      nativeAddress: rawPosition.runeAddress,
      nativeIcon,
      nativePending: AssetValue.from({
        asset: 'MAYA.CACAO',
        value: rawPosition.runePending,
        fromBaseDecimal: 10,
      }),
      nativeWithdrawn: AssetValue.from({
        asset: 'MAYA.CACAO',
        value: rawPosition.runeWithdrawn,
        fromBaseDecimal: 10,
      }),
      // poolShare: new SwapKitNumber(rawPosition.sharedUnits).div(rawPosition.poolUnits),

      dateLastAddedFormatted:
        rawPosition.dateLastAdded !== '0'
          ? formatDateFromUnixTimestamp(rawPosition.dateLastAdded)
          : '',
      dateFirstAddedFormatted:
        rawPosition.dateFirstAdded !== '0'
          ? formatDateFromUnixTimestamp(rawPosition.dateFirstAdded)
          : '',
    }
  })
}

export async function getTHORLiquidityPositions(addresses: string[]): Promise<LiquidityPosition[]> {
  const rawLiquidityPositions: LiquidityPositionTHORRaw[] =
    (await SwapKitApi.getLiquidityPositionsRaw(addresses)) ?? [] // * may be undefined
  return rawLiquidityPositions.map((rawPosition): LiquidityPosition => {
    const asset = AssetValue.from({
      asset: rawPosition.pool,
      value: rawPosition.assetAdded,
      fromBaseDecimal: BaseDecimal.THOR,
    })
    const assetIcon = getAssetIcon(asset.toString())

    const native = AssetValue.from({
      asset: 'THOR.RUNE',
      value: rawPosition.runeAdded,
      fromBaseDecimal: BaseDecimal.THOR,
    })
    const nativeIcon = getAssetIcon(native.toString())
    return {
      ...rawPosition,
      liquidityUnits: rawPosition.sharedUnits,
      provider: 'thorchain',
      assetRegisteredAddress: rawPosition.assetAddress,
      asset,
      assetAmountDisplay: toPrice(asset.getValue('string'), asset.ticker),
      assetIcon,
      assetPending: AssetValue.from({
        asset: rawPosition.pool,
        value: rawPosition.assetPending,
        fromBaseDecimal: BaseDecimal.THOR,
      }),
      assetWithdrawn: AssetValue.from({
        asset: rawPosition.pool,
        value: rawPosition.assetWithdrawn,
        fromBaseDecimal: BaseDecimal.THOR,
      }),
      native,
      nativeAmountDisplay: toPrice(native.getValue('string'), native.ticker),
      nativeAddress: rawPosition.runeAddress,
      nativeIcon,
      nativePending: AssetValue.from({
        asset: 'MAYA.CACAO',
        value: rawPosition.runePending,
        fromBaseDecimal: 10,
      }),
      nativeWithdrawn: AssetValue.from({
        asset: 'MAYA.CACAO',
        value: rawPosition.runeWithdrawn,
        fromBaseDecimal: 10,
      }),
      // poolShare: new SwapKitNumber(rawPosition.sharedUnits).div(rawPosition.poolUnits),

      dateLastAddedFormatted:
        rawPosition.dateLastAdded !== '0'
          ? formatDateFromUnixTimestamp(rawPosition.dateLastAdded)
          : 'pending',
      dateFirstAddedFormatted:
        rawPosition.dateFirstAdded !== '0'
          ? formatDateFromUnixTimestamp(rawPosition.dateFirstAdded)
          : 'pending',
    }
  })
}

export async function getMAYALiquidityPositionDetail(
  pool: string,
  address: string,
): Promise<LiquidityPositionDetail> {
  const liquidityPositionDetailRaw = await RequestClient.get<LiquidityPositionDetailMAYARaw>(
    `https://mayanode.mayachain.info/mayachain/pool/${pool}/liquidity_provider/${address}`,
  )
  const assetDeposit = AssetValue.from({
    asset: liquidityPositionDetailRaw.asset,
    value: liquidityPositionDetailRaw.asset_deposit_value,
    fromBaseDecimal: BaseDecimal.MAYA,
  })
  const assetPending = AssetValue.from({
    asset: liquidityPositionDetailRaw.asset,
    value: liquidityPositionDetailRaw.pending_asset,
    fromBaseDecimal: BaseDecimal.MAYA,
  })
  const assetRedeem = AssetValue.from({
    asset: liquidityPositionDetailRaw.asset,
    value: liquidityPositionDetailRaw.asset_redeem_value,
    fromBaseDecimal: BaseDecimal.MAYA,
  })
  const assetEarnings = AssetValue.from({
    asset: liquidityPositionDetailRaw.asset,
    value:
      parseInt(liquidityPositionDetailRaw.asset_redeem_value) -
      parseInt(liquidityPositionDetailRaw.asset_deposit_value),
    fromBaseDecimal: BaseDecimal.MAYA,
  })
  const nativeDeposit = AssetValue.from({
    asset: 'MAYA.CACAO',
    value: liquidityPositionDetailRaw.cacao_deposit_value,
    fromBaseDecimal: 10,
  })
  const nativePending = AssetValue.from({
    asset: 'MAYA.CACAO',
    value: liquidityPositionDetailRaw.pending_cacao,
    fromBaseDecimal: 10,
  })

  const nativeRedeem = AssetValue.from({
    asset: 'MAYA.CACAO',
    value: liquidityPositionDetailRaw.cacao_redeem_value,
    fromBaseDecimal: 10,
  })

  const nativeEarnings = AssetValue.from({
    asset: 'MAYA.CACAO',
    value:
      parseInt(liquidityPositionDetailRaw.cacao_redeem_value) -
      parseInt(liquidityPositionDetailRaw.cacao_deposit_value),
    fromBaseDecimal: 10,
  })
  const assetChange =
    parseInt(liquidityPositionDetailRaw.asset_redeem_value) /
      parseInt(liquidityPositionDetailRaw.asset_deposit_value) -
    1

  const nativeChange =
    parseInt(liquidityPositionDetailRaw.cacao_redeem_value) /
      parseInt(liquidityPositionDetailRaw.cacao_deposit_value) -
    1

  const liquidityPositionDetail = {
    assetDeposit,
    assetDepositDisplay: toPrice(assetDeposit.getValue('number'), liquidityPositionDetailRaw.asset),
    assetPending,
    assetPendingDisplay: toPrice(assetPending.getValue('number'), liquidityPositionDetailRaw.asset),
    assetRedeem,
    assetRedeemDisplay: toPrice(
      toPrice(assetRedeem.getValue('number'), liquidityPositionDetailRaw.asset),
      liquidityPositionDetailRaw.asset,
    ),
    assetEarnings,
    assetEarningsDisplay: toPrice(
      assetEarnings.getValue('number'),
      liquidityPositionDetailRaw.asset,
    ),
    assetChange,
    assetChangeDisplay: isNaN(assetChange) ? '-' : (assetChange * 100).toFixed(2) + '%',
    nativeDeposit,
    nativeDepositDisplay: toPrice(nativeDeposit.getValue('number'), 'MAYA.CACAO'),
    nativePending,
    nativePendingDisplay: toPrice(nativePending.getValue('number'), 'MAYA.CACAO'),
    nativeRedeem,
    nativeRedeemDisplay: toPrice(nativeRedeem.getValue('number'), 'MAYA.CACAO'),
    nativeEarnings,
    nativeEarningsDisplay: toPrice(nativeEarnings.getValue('number'), 'MAYA.CACAO'),
    nativeChange,
    nativeChangeDisplay: isNaN(nativeChange) ? '-' : (nativeChange * 100).toFixed(2) + '%',
  }
  return liquidityPositionDetail
}

export async function getTHORLiquidityPositionDetail(
  pool: string,
  address: string,
): Promise<LiquidityPositionDetail> {
  const liquidityPositionDetailRaw = await RequestClient.get<LiquidityPositionDetailTHORRaw>(
    `https://thornode.ninerealms.com/thorchain/pool/${pool}/liquidity_provider/${address}`,
  )
  const assetDeposit = AssetValue.from({
    asset: liquidityPositionDetailRaw.asset,
    value: liquidityPositionDetailRaw.asset_deposit_value,
    fromBaseDecimal: BaseDecimal.THOR,
  })
  const assetPending = AssetValue.from({
    asset: liquidityPositionDetailRaw.asset,
    value: liquidityPositionDetailRaw.pending_asset,
    fromBaseDecimal: BaseDecimal.THOR,
  })
  const assetRedeem = AssetValue.from({
    asset: liquidityPositionDetailRaw.asset,
    value: liquidityPositionDetailRaw.asset_redeem_value,
    fromBaseDecimal: BaseDecimal.THOR,
  })
  const assetEarnings = AssetValue.from({
    asset: liquidityPositionDetailRaw.asset,
    value:
      parseInt(liquidityPositionDetailRaw.asset_deposit_value) -
      parseInt(liquidityPositionDetailRaw.asset_redeem_value),
    fromBaseDecimal: BaseDecimal.THOR,
  })
  const nativeDeposit = AssetValue.from({
    asset: 'THOR.RUNE',
    value: liquidityPositionDetailRaw.rune_deposit_value,
    fromBaseDecimal: BaseDecimal.THOR,
  })
  const nativePending = AssetValue.from({
    asset: 'THOR.RUNE',
    value: liquidityPositionDetailRaw.pending_rune,
    fromBaseDecimal: BaseDecimal.THOR,
  })
  const nativeRedeem = AssetValue.from({
    asset: 'THOR.RUNE',
    value: liquidityPositionDetailRaw.rune_redeem_value,
    fromBaseDecimal: BaseDecimal.THOR,
  })
  const nativeEarnings = AssetValue.from({
    asset: 'THOR.RUNE',
    value:
      parseInt(liquidityPositionDetailRaw.rune_deposit_value) -
      parseInt(liquidityPositionDetailRaw.rune_redeem_value),
    fromBaseDecimal: BaseDecimal.THOR,
  })
  const assetChange =
    parseInt(liquidityPositionDetailRaw.asset_deposit_value) /
      parseInt(liquidityPositionDetailRaw.asset_redeem_value) -
    1

  const nativeChange =
    parseInt(liquidityPositionDetailRaw.rune_deposit_value) /
      parseInt(liquidityPositionDetailRaw.rune_redeem_value) -
    1

  const liquidityPositionDetail = {
    assetDeposit,
    assetDepositDisplay: toPrice(assetDeposit.getValue('number'), liquidityPositionDetailRaw.asset),
    assetPending,
    assetPendingDisplay: toPrice(assetPending.getValue('number'), liquidityPositionDetailRaw.asset),
    assetRedeem,
    assetRedeemDisplay: toPrice(
      toPrice(assetRedeem.getValue('number'), liquidityPositionDetailRaw.asset),
      liquidityPositionDetailRaw.asset,
    ),
    assetEarnings,
    assetEarningsDisplay: toPrice(
      assetDeposit.getValue('number') - assetRedeem.getValue('number'),
      liquidityPositionDetailRaw.asset,
    ),
    assetChange,
    assetChangeDisplay: isNaN(assetChange) ? '-' : (assetChange * 100).toFixed(2) + '%',
    nativeDeposit,
    nativeDepositDisplay: toPrice(nativeDeposit.getValue('number'), 'THOR.RUNE'),
    nativePending,
    nativePendingDisplay: toPrice(nativePending.getValue('number'), 'THOR.RUNE'),
    nativeRedeem,
    nativeRedeemDisplay: toPrice(nativeRedeem.getValue('number'), 'THOR.RUNE'),
    nativeEarnings,
    nativeEarningsDisplay: toPrice(nativeEarnings.getValue('number'), 'THOR.RUNE'),
    nativeChange,
    nativeChangeDisplay: isNaN(nativeChange) ? '-' : (nativeChange * 100).toFixed(2) + '%',
  }

  return liquidityPositionDetail
}

const thorchainMidgardBaseUrl = 'https://midgard.ninerealms.com'
const mayachainMidgardBaseUrl = 'https://midgard.mayachain.info'
export function getSaverDetailsRaw(addresses: string[], isMayachain = false) {
  return RequestClient.get<SaverDetails>(
    `${isMayachain ? mayachainMidgardBaseUrl : thorchainMidgardBaseUrl}/v2/saver/${addresses.join(',')}`,
  )
}

export async function getSaverDetails(
  addresses: string[],
  isMayachain = false,
): Promise<SaverDetail[]> {
  const rawSaverPositions: SaverDetails | undefined = await getSaverDetailsRaw(
    addresses,
    isMayachain,
  )

  return (rawSaverPositions?.pools ?? []).map((rawPosition) => {
    const assetAdded = AssetValue.from({
      asset: rawPosition.pool,
      value: rawPosition.assetAdded,
      fromBaseDecimal: BaseDecimal.THOR,
    })

    const assetDeposit = AssetValue.from({
      asset: rawPosition.pool,
      value: rawPosition.assetDeposit,
      fromBaseDecimal: BaseDecimal.THOR,
    })

    const assetRedeem = AssetValue.from({
      asset: rawPosition.pool,
      value: rawPosition.assetRedeem,
      fromBaseDecimal: BaseDecimal.THOR,
    })

    const assetEarnings = AssetValue.from({
      asset: rawPosition.pool,
      value: parseInt(rawPosition.assetRedeem) - parseInt(rawPosition.assetDeposit),
      fromBaseDecimal: BaseDecimal.THOR,
    })

    const assetWithdrawn = AssetValue.from({
      asset: rawPosition.pool,
      value: rawPosition.assetWithdrawn,
      fromBaseDecimal: BaseDecimal.THOR,
    })

    const assetChange = parseInt(rawPosition.assetDeposit) / parseInt(rawPosition.assetRedeem) - 1

    return {
      assetRegisteredAddress: rawPosition.assetAddress,
      pool: rawPosition.pool,
      assetAdded,
      assetAddedDisplay: toPrice(assetAdded.getValue('number'), assetAdded.ticker),
      assetIcon: getAssetIcon(assetAdded.toString()),
      assetDeposit,
      assetDepositDisplay: toPrice(assetDeposit.getValue('number'), assetDeposit.ticker),
      assetRedeem,
      assetRedeemDisplay: toPrice(assetRedeem.getValue('number'), assetRedeem.ticker),
      assetEarnings,
      assetEarningsDisplay: toPrice(assetEarnings.getValue('number'), assetEarnings.ticker),
      assetWithdrawn,
      assetWithdrawnDisplay: toPrice(assetWithdrawn.getValue('number'), assetWithdrawn.ticker),
      assetChange,
      assetChangeDisplay: isNaN(assetChange) ? '-' : (assetChange * 100).toFixed(2) + '%',
      dateLastAdded: rawPosition.dateLastAdded,
      dateFirstAdded: rawPosition.dateFirstAdded,
      dateLastAddedFormatted:
        rawPosition.dateLastAdded !== '0'
          ? formatDateFromUnixTimestamp(rawPosition.dateLastAdded)
          : '',
      dateFirstAddedFormatted:
        rawPosition.dateFirstAdded !== '0'
          ? formatDateFromUnixTimestamp(rawPosition.dateFirstAdded)
          : '',
      provider: isMayachain ? 'mayachain' : 'thorchain',
      providerIcon: getAssetIcon(isMayachain ? 'MAYA.CACAO' : 'THOR.RUNE'),
    }
  })
}

export async function getTHORDepositQuote({
  asset,
  amount,
}: SaverQuoteDepositParamsRaw): Promise<SaversQuoteRaw> {
  const res = await RequestClient.get<SaversQuoteRaw>(
    `https://thornode.ninerealms.com/thorchain/quote/saver/deposit?amount=${amount}&asset=${asset}`,
  )

  return res
}

export async function getTHORWithdrawQuote({
  address,
  asset,
  withdrawBps,
}: SaverQuoteWithdrawParamsRaw): Promise<SaversQuoteRaw> {
  const res = await RequestClient.get<SaversQuoteRaw>(
    `https://thornode.ninerealms.com/thorchain/quote/saver/withdraw?address=${address}&withdraw_bps=${withdrawBps}&asset=${asset}`,
  )

  return res
}

export async function getTHORSaversQuote(params: SaverQuoteParams): Promise<SaversQuote> {
  const { asset } = params.payload

  let rawQuote: SaversQuoteRaw
  if (params.type === 'deposit') {
    const { amount } = params.payload
    rawQuote = await getTHORDepositQuote({ asset, amount })
  } else if (params.type === 'withdraw') {
    const { address, withdrawBps } = params.payload
    rawQuote = await getTHORWithdrawQuote({ address, asset, withdrawBps })
  } else {
    throw new Error('Invalid quote type')
  }

  const quote: SaversQuote = {
    inboundAddress: rawQuote.inbound_address,
    inboundConfirmationBlocks: rawQuote.inbound_confirmation_blocks,
    inboundConfirmationSeconds: rawQuote.inbound_confirmation_seconds,
    fees: {
      asset: rawQuote.fees.asset,
      affiliate: AssetValue.from({
        asset,
        value: rawQuote.fees.affiliate,
        fromBaseDecimal: BaseDecimal.THOR,
      }),
      outbound: AssetValue.from({
        asset,
        value: rawQuote.fees.outbound,
        fromBaseDecimal: BaseDecimal.THOR,
      }),
      liquidity: AssetValue.from({
        asset,
        value: rawQuote.fees.liquidity,
        fromBaseDecimal: BaseDecimal.THOR,
      }),
      total: AssetValue.from({
        asset,
        value: rawQuote.fees.total,
        fromBaseDecimal: BaseDecimal.THOR,
      }),
      slippageBps: rawQuote.fees.slippage_bps,
      totalBps: rawQuote.fees.total_bps,
    },
    slippageBps: rawQuote.slippage_bps,
    expiry: rawQuote.expiry,
    warning: rawQuote.warning,
    notes: rawQuote.notes,
    recommendedGasRate: rawQuote.recommended_gas_rate,
    gasRateUnits: rawQuote.gas_rate_units,
    memo: rawQuote.memo,
    expectedAmountOut: AssetValue.from({
      asset,
      value: rawQuote.expected_amount_out,
      fromBaseDecimal: BaseDecimal.THOR,
    }),
    expectedAmountDeposit: AssetValue.from({
      asset,
      value: rawQuote.expected_amount_deposit,
      fromBaseDecimal: BaseDecimal.THOR,
    }),
  }

  return quote
}

export function getLogoForChain(chain: string) {
  if (chain === 'SOLANA') {
    return 'https://coin-images.coingecko.com/coins/images/4128/large/solana.png'
  }
  const baseUrl = 'https://static.thorswap.net'
  const chainIdentifier = getChainIdentifier(chain as Chain).toLowerCase()
  return `${baseUrl}/token-list/images/${chainIdentifier}.png`
}

export const normalizedProviderNames: { [key: string]: string } = {
  MAYACHAIN: 'Mayachain',
  MAYACHAIN_STREAMING: 'Mayachain',
  THORCHAIN: 'Thorchain',
  THORCHAIN_STREAMING: 'Thorchain',
  CHAINFLIP: 'Chainflip',
  ONEINCH: '1Inch',
  UNISWAP: 'Uniswap V1',
  UNISWAP_V1: 'Uniswap V1',
  UNISWAPV2: 'Uniswap V2',
  Uniswap_V2: 'Uniswap V2',
  KYBERSWAP: 'KyberSwap',
  KYBERSWAPV2: 'KyberSwap V2',
  KYBERSWAP_V2: 'KyberSwap V2',
  TRADERJOE: 'Traderjoe',
  PLATYPUS: 'Platypus',
  BALANCERV2: 'Balancer',
  UNISWAPV3: 'Uniswap V3',
  Uniswap_V3: 'Uniswap V3',
  ZEROX: '0x',
  CURVEV2: 'Curve V2',
  Curve_V2: 'Curve V2',
  CURVE: 'Curve',
  SUSHISWAP: 'Sushiswap',
  SUSHI: 'Sushiswap',
  SYNTHETIXATOMIC: 'Syntetix',
  SYNTHETIX_ATOMIC_SIP288: 'Syntetix',
  KYBERSWAPELASTIC: 'KyberSwap',
  SYNAPSE: 'Synapse',
  AVALANCHE_TRADERJOE_V2: 'Traderjoe V2',
  'WOOFI-AVAX': 'WOOFI',
}

export const getNormalizedProviderName = (provider: string) => {
  const name =
    normalizedProviderNames[provider.toUpperCase() as keyof typeof normalizedProviderNames]

  if (!name) {
    window.newrelic?.noticeError(`Unknown provider, couldn't resolve normalized name: ${provider}`)
  }
  return name || provider
}

export const getFullChainName = (chainId?: ChainId | '900') => {
  if (chainId) {
    const chainName = (Object.keys(ChainId) as Array<keyof typeof ChainId>).find(
      (key) => ChainId[key] === chainId,
    )
    return chainName === 'Maya' ? 'MAYAChain' : chainName
  } else {
    return ''
  }
}

export const tokenListProviderSchema = z.object({
  provider: z.nativeEnum(ProviderName),
  name: z.string(),
  url: z.string().url(),
})
export const tokenListProvidersSchema = z.array(tokenListProviderSchema)

/**
 * AssetValue.isGasAsset returns false for SOLANA.SOL
 * KUJI.USK doesn't have an assetAddress
 */
export const isGasAsset = ({
  address,
  amount,
  assetId,
}: {
  address: string | null
  amount: number
  assetId: string
}) => {
  return !address && AssetValue.from({ asset: assetId, value: amount }).isGasAsset
}

export const getOutputAmountMin = ({
  expectedBuyAmountMaxSlippage,
  expectedOutput,
  slippagePercentage,
}: {
  expectedBuyAmountMaxSlippage: string
  expectedOutput: BigNumber
  slippagePercentage: number
}) => {
  return expectedBuyAmountMaxSlippage === '0'
    ? expectedOutput
        .multipliedBy(100 - slippagePercentage)
        .div(100)
        .toString()
    : expectedBuyAmountMaxSlippage
}

export function getTokenListV2(provider: ProviderName) {
  return RequestClient.get<TokensResponseV2>(`https://api.swapkit.dev/tokens?provider=${provider}`)
}

export async function getTokenTradingPairs(providers: ProviderName[]) {
  const tradingPairs = new Map<
    string,
    {
      tokens: TokensResponseV2['tokens']
      providers: ProviderName[]
    }
  >()

  if (!providers.length) return tradingPairs

  const providerRequests = providers.map(async (provider) => {
    const tokenList = await getTokenListV2(provider)
    return tokenList
  })

  const providersData = (await Promise.all(providerRequests))
    .filter((provider) => !!provider)
    .map(({ tokens, ...rest }) => ({
      data: {
        ...(rest || {}),
        tokens: tokens.map(({ address, ...rest }) => ({
          ...rest,
          ...(address &&
          [
            'resource_rdx1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxradxrd',
            '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
          ].includes(address.toLowerCase())
            ? {}
            : { address }),
        })),
      },
      ...rest,
    }))

  const UNCHAINABLE_PROVIDERS = [
    ProviderName.CAVIAR_V1,
    ProviderName.CHAINFLIP,
    ProviderName.MAYACHAIN,
    ProviderName.MAYACHAIN_STREAMING,
  ]

  const chainableTokens = providersData
    .filter(({ data }) => {
      return !UNCHAINABLE_PROVIDERS.includes((data?.provider || '') as ProviderName)
    })
    .reduce(
      (acc, { data }) => (data?.tokens ? acc.concat(data.tokens) : acc),
      [] as TokensResponseV2['tokens'],
    )
  for (const { data } of providersData) {
    if (!data?.tokens) return
    const isProviderChainable =
      data.provider && !UNCHAINABLE_PROVIDERS.includes(data.provider as ProviderName)

    for (const token of data.tokens) {
      const existingTradingPairs = tradingPairs.get(token.identifier.toLowerCase()) || {
        tokens: [],
        providers: [],
      }

      const tradingPairsForToken = isProviderChainable
        ? {
            tokens: chainableTokens,
            providers: [
              ProviderName.THORCHAIN,
              ProviderName.THORCHAIN_STREAMING,
              ProviderName.PANCAKESWAP,
              ProviderName.ONEINCH,
              ProviderName.PANGOLIN_V1,
              ProviderName.SUSHISWAP_V2,
              ProviderName.TRADERJOE_V2,
              ProviderName.UNISWAP_V3,
              ProviderName.UNISWAP_V2,
            ],
          }
        : { tokens: data.tokens, providers: data.provider }

      tradingPairs.set(token.identifier.toLowerCase(), {
        tokens: existingTradingPairs.tokens.concat(tradingPairsForToken.tokens),
        providers: existingTradingPairs.providers.concat(tradingPairsForToken.providers),
      })
    }
  }

  return tradingPairs
}
