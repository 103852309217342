import { default as buyPTGS4Eqc58Meta } from "/vercel/path0/pages/buy.vue?macro=true";
import { default as earnOAjJ0WbhelMeta } from "/vercel/path0/pages/earn.vue?macro=true";
import { default as leaderboardk2Vl1j8FuPMeta } from "/vercel/path0/pages/leaderboard.vue?macro=true";
import { default as marketsjMu55Bz25kMeta } from "/vercel/path0/pages/markets.vue?macro=true";
import { default as p2pE3VwoyMAMpMeta } from "/vercel/path0/pages/p2p.vue?macro=true";
import { default as swapwGvG0t6ytpMeta } from "/vercel/path0/pages/swap.vue?macro=true";
import { default as basicstCJ9RZ7NgCMeta } from "/vercel/path0/pages/tutorial/basics.vue?macro=true";
import { default as cccH3zMxGX48zMeta } from "/vercel/path0/pages/tutorial/ccc.vue?macro=true";
import { default as eldorado7Oyq6dne4uMeta } from "/vercel/path0/pages/tutorial/eldorado.vue?macro=true";
import { default as walletNmivtXEuWQMeta } from "/vercel/path0/pages/wallet.vue?macro=true";
import { default as component_45stubUHgG5aOkzGMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: buyPTGS4Eqc58Meta?.name ?? "buy",
    path: buyPTGS4Eqc58Meta?.path ?? "/buy",
    meta: buyPTGS4Eqc58Meta || {},
    alias: buyPTGS4Eqc58Meta?.alias || [],
    redirect: buyPTGS4Eqc58Meta?.redirect,
    component: () => import("/vercel/path0/pages/buy.vue").then(m => m.default || m)
  },
  {
    name: earnOAjJ0WbhelMeta?.name ?? "earn",
    path: earnOAjJ0WbhelMeta?.path ?? "/earn",
    meta: earnOAjJ0WbhelMeta || {},
    alias: earnOAjJ0WbhelMeta?.alias || [],
    redirect: earnOAjJ0WbhelMeta?.redirect,
    component: () => import("/vercel/path0/pages/earn.vue").then(m => m.default || m)
  },
  {
    name: leaderboardk2Vl1j8FuPMeta?.name ?? "leaderboard",
    path: leaderboardk2Vl1j8FuPMeta?.path ?? "/leaderboard",
    meta: leaderboardk2Vl1j8FuPMeta || {},
    alias: leaderboardk2Vl1j8FuPMeta?.alias || [],
    redirect: leaderboardk2Vl1j8FuPMeta?.redirect,
    component: () => import("/vercel/path0/pages/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: marketsjMu55Bz25kMeta?.name ?? "markets",
    path: marketsjMu55Bz25kMeta?.path ?? "/markets",
    meta: marketsjMu55Bz25kMeta || {},
    alias: marketsjMu55Bz25kMeta?.alias || [],
    redirect: marketsjMu55Bz25kMeta?.redirect,
    component: () => import("/vercel/path0/pages/markets.vue").then(m => m.default || m)
  },
  {
    name: p2pE3VwoyMAMpMeta?.name ?? "p2p",
    path: p2pE3VwoyMAMpMeta?.path ?? "/p2p",
    meta: p2pE3VwoyMAMpMeta || {},
    alias: p2pE3VwoyMAMpMeta?.alias || [],
    redirect: p2pE3VwoyMAMpMeta?.redirect,
    component: () => import("/vercel/path0/pages/p2p.vue").then(m => m.default || m)
  },
  {
    name: swapwGvG0t6ytpMeta?.name ?? "swap",
    path: swapwGvG0t6ytpMeta?.path ?? "/swap",
    meta: swapwGvG0t6ytpMeta || {},
    alias: swapwGvG0t6ytpMeta?.alias || [],
    redirect: swapwGvG0t6ytpMeta?.redirect,
    component: () => import("/vercel/path0/pages/swap.vue").then(m => m.default || m)
  },
  {
    name: basicstCJ9RZ7NgCMeta?.name ?? "tutorial-basics",
    path: basicstCJ9RZ7NgCMeta?.path ?? "/tutorial/basics",
    meta: basicstCJ9RZ7NgCMeta || {},
    alias: basicstCJ9RZ7NgCMeta?.alias || [],
    redirect: basicstCJ9RZ7NgCMeta?.redirect,
    component: () => import("/vercel/path0/pages/tutorial/basics.vue").then(m => m.default || m)
  },
  {
    name: cccH3zMxGX48zMeta?.name ?? "tutorial-ccc",
    path: cccH3zMxGX48zMeta?.path ?? "/tutorial/ccc",
    meta: cccH3zMxGX48zMeta || {},
    alias: cccH3zMxGX48zMeta?.alias || [],
    redirect: cccH3zMxGX48zMeta?.redirect,
    component: () => import("/vercel/path0/pages/tutorial/ccc.vue").then(m => m.default || m)
  },
  {
    name: eldorado7Oyq6dne4uMeta?.name ?? "tutorial-eldorado",
    path: eldorado7Oyq6dne4uMeta?.path ?? "/tutorial/eldorado",
    meta: eldorado7Oyq6dne4uMeta || {},
    alias: eldorado7Oyq6dne4uMeta?.alias || [],
    redirect: eldorado7Oyq6dne4uMeta?.redirect,
    component: () => import("/vercel/path0/pages/tutorial/eldorado.vue").then(m => m.default || m)
  },
  {
    name: walletNmivtXEuWQMeta?.name ?? "wallet",
    path: walletNmivtXEuWQMeta?.path ?? "/wallet",
    meta: walletNmivtXEuWQMeta || {},
    alias: walletNmivtXEuWQMeta?.alias || [],
    redirect: walletNmivtXEuWQMeta?.redirect,
    component: () => import("/vercel/path0/pages/wallet.vue").then(m => m.default || m)
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: () => import("/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]